(function($, window, undefined) {
    // Theme main JS
    var Theme = (function(){

        function navigation(){
            $('[data-toggle=offcanvas]').click(function() {
                $('.row-offcanvas').toggleClass('active');
            });



            $('li.menu-item-has-children > a').contents().unwrap();
            $('#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4').click(function(){
                $(this).toggleClass('open');
            });
        }


        function gmap(){
            var $gMaps = document.getElementsByClassName( 'gm-canvas' );
            if ( !$gMaps.length ) return;

            try {
                window.gMapsCallback();
            }
            catch( e ) {
                var gMapsApi = document.createElement( 'script' );
                gMapsApi.setAttribute( 'type', 'text/javascript' );
                gMapsApi.setAttribute( 'src', 'http://maps.google.com/maps/api/js?sensor=false&callback=gMapsCallback' );
                ( document.getElementsByTagName( 'head' )[0] || document.documentElement ).appendChild( gMapsApi );

                window.gMapsCallback = function() {
                    for (var i = 0; i < $gMaps.length; i++ ) {
                        var $gMap = $gMaps[i],
                            sAddress = $gMap.getElementsByTagName( 'address' )[0].innerHTML.split( "\n" ),
                            sInfo = $gMap.getElementsByClassName( 'info' )[0] || sAddress.join( '<br />' ),
                            geocoder = new google.maps.Geocoder();

                        geocoder.geocode( { address: sAddress.join( ', ' ) }, function( results, status ) {
                            if ( status != google.maps.GeocoderStatus.OK ) return;

                            // map options
                            var options = {
                                center: results[0].geometry.location
                                ,scrollwheel: false
                                ,mapTypeId: google.maps.MapTypeId.ROADMAP
                                ,zoom: 16
                                ,disableDefaultUI: true

                                ,mapTypeControl: true
                                //,zoomControl: false
                                //,streetViewControl: false
                                //,scaleControl: true

                            };

                            // create map
                            var map = new google.maps.Map( $gMap, options),
                            // create marker
                                marker = new google.maps.Marker( {
                                    map: map,
                                    position: results[0].geometry.location,
                                    title: sAddress.join( ', ' )
                                }),
                            // create info window
                                info = new google.maps.InfoWindow( {
                                    content: sInfo
                                } );

                            var styles = [
                                {
                                    stylers: [
                                        { saturation: -100 }
                                    ]
                                }
                            ];

                            marker.addListener( 'click', function() {
                                info.open( map, marker );
                            } );

                            google.maps.event.addListenerOnce( map, 'idle', function() {
                                google.maps.event.trigger( map, 'resize' );
                                map.setCenter(results[0].geometry.location);
                                map.setOptions({styles: styles});
                            } );

                        } );
                    }
                };
            }
        }

        return {
            init: function(){
                navigation();
                gmap();
            }
        };

    })();

    Theme.init();

})(jQuery, window, undefined); // Fully reference jQuery after this point.